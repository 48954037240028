import React, { useState, useEffect, useRef } from 'react';
import { getSupabase, checkSupabaseConnection } from '../lib/supabaseClient';
import { ArrowRight, Database, BarChart2, Shield, ChevronRight } from 'lucide-react';
import { trackEvent } from '../utils/analytics';
const LandingPage = () => {
  const [showForm, setShowForm] = useState(false);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [email, setEmail] = useState('');
  const [subscribeStatus, setSubscribeStatus] = useState({ loading: false, error: null });
  const [formStep, setFormStep] = useState(0);
  const [formData, setFormData] = useState({
    role: '',
    otherRole: '',
    institution: '',
    otherInstitution: '',
    currentState: '',
    otherState: '',
    applicationsFrequency: '',
    selectedChallenges: [],
    customChallenge: '',
    suggestions: ''
  });
  const [submitStatus, setSubmitStatus] = useState({ loading: false, error: null });
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  const modalRef = useRef(null);


  // First useEffect: Initial setup and connection checks
  useEffect(() => {
    // Single initialization check
    const isGAAvailable = !!window.gtag;
    console.log('Google Analytics status:', isGAAvailable ? 'initialized' : 'not available');
    
    // Check Supabase once
    checkSupabaseConnection()
        .then(isConnected => {
        console.log('Supabase connection status:', isConnected);
        });
      }, []);
  
  // Third useEffect: Modal click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showForm && modalRef.current && !modalRef.current.contains(event.target)) {
        if (formStep === 0) {
          setShowForm(false);
          setFormStep(0);
          setFormData({ role: '', institution: '', challenges: '' });
          setSubmitStatus({ loading: false, error: null });
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showForm, formStep]);

  const handleStartForm = () => {
    trackEvent('form_open', 'Challenge Form');
    setShowForm(true);
    };

  const handleLearnMore = () => {
    trackEvent('learn_more', 'Updates Form');
    setShowSubscribe(true);
    };


  const handleFormSubmit = async () => {
    trackEvent('form_submit', `${formData.role} - ${formData.institution}`);
    setSubmitStatus({ loading: true, error: null });
    
    const supabase = getSupabase();
    if (!supabase) {
      console.log('Form submission (no Supabase):', formData);
      setFormStep(2);
      return;
    }

    try {
      const submissionData = {
        selected_role: formData.role === 'other' ? 'custom' : formData.role,
        custom_role: formData.role === 'other' ? formData.otherRole : null,
        selected_institution: formData.institution === 'other' ? 'custom' : formData.institution,
        custom_institution: formData.institution === 'other' ? formData.otherInstitution : null,
        selected_state: formData.currentState === 'other' ? 'custom' : formData.currentState,
        custom_state: formData.currentState === 'other' ? formData.otherState : null,
        applications_frequency: formData.applicationsFrequency,
        selected_challenges: formData.selectedChallenges,
        custom_challenge: formData.customChallenge || null,
        suggestions: formData.suggestions || null,
        submitted_at: new Date().toISOString()
      };

      console.log('Submitting to Supabase:', submissionData);

      const { data, error } = await supabase
        .from('form_submissions')
        .insert([submissionData])
        .select();

      if (error) {
        console.error('Supabase error details:', {
          code: error.code,
          message: error.message,
          details: error.details
        });
        throw error;
      }

      console.log('Submission successful:', data);
      setFormStep(2);
    } catch (error) {
      console.error('Detailed submission error:', error);
      setSubmitStatus({ 
        loading: false, 
        error: `Failed to submit form: ${error.message}` 
      });
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubscribe = async () => {
    trackEvent('newsletter_subscribe', email);
    if (!isValidEmail(email)) {
      setSubscribeStatus({ 
        loading: false, 
        error: 'Please enter a valid email address' 
      });
      return;
    }

    setSubscribeStatus({ loading: true, error: null });
    
    const supabase = getSupabase();
    if (!supabase) {
      console.log('Subscription (no Supabase):', email);
      setSubscribeSuccess(true);
      return;
    }

    try {
      const { data: existingSubscription } = await supabase
        .from('subscriptions')
        .select('email')
        .eq('email', email)
        .single();

      if (existingSubscription) {
        setSubscribeStatus({ 
          loading: false, 
          error: 'This email is already subscribed to our updates.' 
        });
        return;
      }

      const { error } = await supabase
        .from('subscriptions')
        .insert([{ email, subscribed_at: new Date().toISOString() }]);

      if (error) throw error;

      setEmail('');
      setSubscribeSuccess(true);
    } catch (error) {
      console.error('Subscription error:', error);
      if (error.code === '23505') {
        setSubscribeStatus({ 
          loading: false, 
          error: 'This email is already subscribed to our updates.' 
        });
      } else {
        setSubscribeStatus({ 
          loading: false, 
          error: 'Failed to subscribe. Please try again.' 
        });
      }
    }
  };

  const handleChallengeToggle = (challenge) => {
    setFormData(prev => ({
      ...prev,
      selectedChallenges: prev.selectedChallenges.includes(challenge)
        ? prev.selectedChallenges.filter(c => c !== challenge)
        : [...prev.selectedChallenges, challenge]
    }));
  };

  return (
    <div className="min-h-screen bg-white relative">
      {/* Hero Section - Made more responsive */}
      <div className="bg-gradient-to-r from-green-50 to-blue-50 py-8 sm:py-12 md:py-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 mb-4 sm:mb-6">
              Empowering Financial Institutions to Scale Sustainable Agriculture
            </h1>
            <p className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-8 max-w-3xl mx-auto">
              AI-powered insights helping lenders evaluate and finance sustainable farming practices with confidence
            </p>
          </div>
        </div>
      </div>

      {/* Value Props - Improved responsiveness and width */}
      <div className="py-8 sm:py-12 md:py-16 bg-white">
        <div className="space-y-4 sm:space-y-6 md:space-y-8">
          {/* Value Prop 1 */}
          <div className="w-full bg-white shadow-lg">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 py-6 sm:py-8">
              <div className="flex flex-col items-center text-center mb-4 sm:mb-6">
                <div className="p-3 bg-green-100 rounded-full mb-3">
                  <Database className="h-6 w-6 sm:h-8 sm:w-8 text-green-600" />
                </div>
                <h3 className="text-lg sm:text-xl font-semibold">A Comprehensive Approach to Sustainable Agriculture</h3>
              </div>
              <p className="text-base sm:text-lg text-gray-600">
                Drawing on a range of reliable data—from scientific publications and government reports to insights from industry leaders—for a robust, evidence-based assessment of sustainable practices.
              </p>
            </div>
          </div>

          {/* Value Prop 2 */}
          <div className="w-full bg-white shadow-lg">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 py-6 sm:py-8">
              <div className="flex flex-col items-center text-center mb-4 sm:mb-6">
                <div className="p-3 bg-blue-100 rounded-full mb-3">
                  <BarChart2 className="h-6 w-6 sm:h-8 sm:w-8 text-blue-600" />
                </div>
                <h3 className="text-lg sm:text-xl font-semibold">Streamlined Risk Assessment</h3>
              </div>
              <p className="text-base sm:text-lg text-gray-600">
                Make informed lending decisions with AI-powered analysis of agricultural practices and their financial viability
              </p>
            </div>
          </div>

          {/* Value Prop 3 */}
          <div className="w-full bg-white shadow-lg">
            <div className="max-w-4xl mx-auto px-4 sm:px-6 py-6 sm:py-8">
              <div className="flex flex-col items-center text-center mb-4 sm:mb-6">
                <div className="p-3 bg-purple-100 rounded-full mb-3">
                  <Shield className="h-6 w-6 sm:h-8 sm:w-8 text-purple-600" />
                </div>
                <h3 className="text-lg sm:text-xl font-semibold">Regulatory Confidence</h3>
              </div>
              <p className="text-base sm:text-lg text-gray-600">
                Stay ahead of sustainability requirements with detailed documentation and transparent assessment criteria
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gray-50 pt-8 sm:pt-12 md:pt-16 min-h-screen">
        <div className="max-w-2xl mx-auto px-4 sm:px-6 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">Help Shape the Future of Agricultural Finance</h2>
          <p className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-8">
            Share your insights to help us better understand and address the challenges in sustainable agriculture financing
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button 
              onClick={handleStartForm}
              className="bg-green-600 text-white px-6 sm:px-8 py-3 sm:py-4 rounded-lg font-semibold hover:bg-green-700 transition-colors flex items-center justify-center text-base sm:text-lg">
              We'd love to hear your challenges
              <ChevronRight className="ml-2 h-5 w-5" />
            </button>
            <button 
              onClick={handleLearnMore}
              className="bg-white border border-green-600 text-green-600 px-6 sm:px-8 py-3 sm:py-4 rounded-lg font-semibold hover:bg-green-50 transition-colors text-base sm:text-lg">
              Learn More
            </button>
          </div>
        </div>
      </div>

      {/* Multi-step Form Modal - Add ref to the modal container */}
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-4xl w-full h-[80vh] relative">
            <button
              onClick={() => setShowForm(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 z-10"
            >
              ✕
            </button>
            <div className="w-full h-full">
              <iframe 
                src="https://forms.gle/99SQndn3dNCEqaRH6"
                className="w-full h-full"
                frameBorder="0" 
                marginHeight="0" 
                marginWidth="0"
              >
                Loading…
              </iframe>
            </div>
          </div>
        </div>
      )}

      {/* Subscribe Modal - Updated with success state */}
      {showSubscribe && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <button
              onClick={() => {
                setShowSubscribe(false);
                setEmail('');
                setSubscribeStatus({ loading: false, error: null });
                setSubscribeSuccess(false);
              }}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
            <div className="text-center">
              {!subscribeSuccess ? (
                <>
                  <h3 className="text-xl font-semibold mb-4">Stay Updated</h3>
                  <p className="text-gray-600 mb-6">
                    Subscribe to receive updates about our latest developments in sustainable agriculture finance.
                  </p>
                  <div className="relative">
                    <input
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (subscribeStatus.error) {
                          setSubscribeStatus({ loading: false, error: null });
                        }
                      }}
                      className={`w-full p-2 border rounded mb-4 ${
                        email && !isValidEmail(email) ? 'border-red-500' : ''
                      }`}
                    />
                    {email && !isValidEmail(email) && (
                      <p className="text-red-500 text-sm text-left mb-4">
                        Please enter a valid email address
                      </p>
                    )}
                  </div>
                  {subscribeStatus.error && (
                    <p className="text-red-500 mb-4">{subscribeStatus.error}</p>
                  )}
                  <div className="flex gap-2">
                    <button
                      onClick={handleSubscribe}
                      disabled={!email || !isValidEmail(email) || subscribeStatus.loading}
                      className={`flex-1 px-4 py-2 rounded font-semibold transition-colors ${
                        !email || !isValidEmail(email) || subscribeStatus.loading
                          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                          : 'bg-green-600 text-white hover:bg-green-700'
                      }`}>
                      {subscribeStatus.loading ? 'Subscribing...' : 'Subscribe'}
                    </button>
                    <button
                      onClick={() => {
                        setShowSubscribe(false);
                        setEmail('');
                        setSubscribeStatus({ loading: false, error: null });
                        setSubscribeSuccess(false);
                      }}
                      className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-50">
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h3 className="text-xl font-semibold mb-4">Thank You!</h3>
                  <p className="text-gray-600 mb-6">
                    We'll keep you updated with the latest developments in sustainable agriculture finance.
                  </p>
                  <button
                    onClick={() => {
                      setShowSubscribe(false);
                      setEmail('');
                      setSubscribeStatus({ loading: false, error: null });
                      setSubscribeSuccess(false);
                    }}
                    className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700">
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;